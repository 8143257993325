@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

body {
    font: 400 14px 'Poppins', sans-serif;
    background: #1D3B8C;
    -webkit-font-smoothing: antialiased;
}

input, button, textarea {
    font: 400 18px 'Poppins', sans-serif;
}

button {
    cursor: pointer;
}

form input {
    width: 100%;
    height: 60px;
    color: #333;
    border: 1px solid #dcdce6;
    border-radius: 8px;
    padding: 0 24px;
    margin: 5px;
}

.button {
    width: 100%;
    height: 60px;
    background: #4BAE50;
    border-radius: 8px;
    border: 0;
    color: #fff;
    font-weight: 700;
    margin-top: 16px;
    display: inline-block;
    text-decoration: none;
    text-align: center;
    font-size: 18px;
    line-height: 60px;
    transition: filter 0.2s;
}

.button:hover {
    filter: brightness(90%);
}