#logo{
    width: 250px;
}

#rocket{
    width: 50%;
    height: auto;
}

.error {
    margin-top: 5px;
    color: white;
}

.logon-container {
    width: 100%;
    max-width: 1120px;
    height: 100vh;
    margin:  0 auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.logon-container section.form {
    width: 100%;
    max-width: 450px;
    margin: 80px;
}

.logon-container section.form form {
    margin-top: 50px;
}

.logon-container section.form form h1 {
    font-size: 30px;
    margin-bottom: 25px;
    color: white;
}

.logon-container section.form form a {
    display: flex;
    align-items: center;
    margin-top: 40px;
    color: #fff;
    font-size: 18px;
    text-decoration: none;
    font-weight: 500;
    transition: opacity 0.2s;
}

.logon-container section.form form a svg {
    margin-right: 10px;
}

.logon-container section.form form a:hover {
    opacity: 0.8;
}

.time_now{
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
}